<template>
  <div>
    <div class='box'>
      <div class='default-table panel' style='width: 25%'>
        <el-card>
          <div slot='header' class='clearfix'>
            <el-link class='title' @click='handleQuery'>同步记录</el-link>
            <el-button type='text' style='float: right' icon='el-icon-plus' @click='dialogVisible=true'></el-button>
          </div>
          <MlPageTable :all-columns='defaultColumns'
                       :table-name='tableName'
                       :data-list='dataList'
                       :limit.sync='pageData.page_size'
                       :page.sync='pageData.current_page'
                       :total.sync='total'
                       :height='height'
                       highlight-current-row
                       :row-class-name='tableRowClassName'
                       @row-click='rowClickEvent'
                       @getList='getList'>
            <el-table-column>
              <template slot-scope='{row}'>
                <el-button type='text' icon='el-icon-document-copy' @click='copyData(row)'>复制</el-button>
              </template>
            </el-table-column>
          </MlPageTable>
        </el-card>

      </div>
      <div class='panel' style='width: 75%'>
        <el-card>
          <div slot='header' class='clearfix'>
            <el-link class='title' @click='handleQuery'>缇苏招聘数据明细表</el-link>
          </div>
          <div>
            <div style='width: 100%;height: auto'>
              <!--              漏斗图-->
              <echarts-chart :width='`100%`' :height='`800px`' :chart-option='chartOption' />
            </div>
            <div class='default-table'>
              <div
                v-loading='loadingGrid'
                element-loading-text='玩命加载中……'
                element-loading-spinner='el-icon-loading'>
                <el-table
                  :data='gridData'
                  show-summary
                  :summary-method='getSummaries'
                  border
                >
                  <el-table-column
                    width='50'
                    label='序号' header-align='center' align='center' fixed>
                    <template slot-scope='scope'>
                      <span>{{ scope.$index + 1 }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-for='(v,k) in gridColumns'
                    :key='k'
                    :prop="v.field?v.field:typeof(v.value)=='string'?v.value:''"
                    :label='v.title'
                    :width='v.width'
                    :show-overflow-tooltip='true'
                    :header-align="v.header_align?v.header_align:'center'"
                    :align="v.align?v.align:'center'"
                    :sortable='v.sortable?v.sortable:false'>
                    <template slot-scope='scope'>
                      <span v-html='scope.row[v.value]'></span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <el-dialog
      title='数据拷贝'
      :visible.sync='dialogVisible'
      width='60%'
    >
      <el-divider>复制数据</el-divider>
      <el-input v-model='gridOrgData' :autosize='{ minRows: 5, maxRows: 10}' type='textarea'></el-input>
      <el-divider>表单数据
        <el-link @click='handleTransfer' icon='el-icon-refresh'></el-link>
      </el-divider>
      <el-form :model='gridForm' label-width='100px'>
        <el-form-item label='同步时间'>
          <el-input v-model='gridForm.create_time'></el-input>
        </el-form-item>
        <el-form-item label='报表ID'>
          <el-input v-model='gridForm.report_id'></el-input>
        </el-form-item>
        <el-form-item label='报表表头'>
          <el-input v-model='gridForm.grid_header'></el-input>
        </el-form-item>
        <el-form-item label='报表数据'>
          <el-input v-model='gridForm.grid_header' :autosize='{ minRows: 15, maxRows: 30}' type='textarea'></el-input>
        </el-form-item>
      </el-form>

      <span slot='footer' class='dialog-footer'>
    <el-button @click='dialogVisible = false'>取 消</el-button>
    <el-button type='primary' :disabled='loadingCommit' :loading='loadingCommit' @click='handleSave'>确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PieChart from '@/pages/dashboard/PieChart'
import EchartsChart from '@/components/EchartsChart'

export default {
  name: 'ReportTable',
  components: { EchartsChart, PieChart, MlPageTable },
  computed: {
    jsonResult() {
      if (this.gridOrgData && this.gridOrgData.indexOf('{') > -1)
        return JSON.stringify(JSON.parse(this.gridOrgData), null, 4)
      else
        return '-'
      // return this.formData.notify_msg || {}
    }
  },
  props: {
    reportId: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      loading: false,
      loadingCommit: false,
      dataList: [{}],
      pageData: { current_page: 1, page_size: 100 },
      total: 0,
      height: 800,
      tableName: 'beisen_report',
      defaultColumns: [
        { hide: true, prop: 'id', label: 'ID', width: 80, sortable: false, fixed: false },
        { hide: true, prop: 'report_id', label: '报表ID', width: 180, sortable: false, fixed: false },
        { hide: false, prop: 'create_time', label: '同步时间', width: 140, sortable: true, fixed: false },
        { hide: true, prop: 'created_at', label: '创建时间', width: 140, sortable: false, fixed: false },
        { hide: true, prop: 'updated_at', label: '修改时间', width: 140, sortable: false, fixed: false }
      ],

      gridColumns: [],
      loadingGrid: false,
      gridData: [],
      gridDataTotal: 0,
      gridPageData: { current_page: 1, page_size: 20 },
      chartOption: {},
      legend: ['简历初筛', '初试', '复试', '拟录', 'Offer环节', '正式录用'],
      dialogVisible: false,
      gridOrgData: null,
      gridForm: {}
    }
  },
  watch: {
    reportId: {
      immediate: true,
      handler() {
        this.handleQuery()
      }
    }
  },
  methods: {

    handleQuery() {
      this.dataList = []
      this.pageData.current_page = 1
      if (this.reportId) {
        this.getList()
        this.gridData = []
        this.gridColumns = []
      }

    },
    async getList() {
      this.loading = true
      let params = { report_id: this.reportId }
      Object.assign(params, this.pageData)
      let { list, pages } = await this.$api.getBeiSenReportList(params)
      this.dataList = [...this.dataList, ...list]
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      // if (pages.total > this.dataList.length) {
      //   //加载下一页
      //   setTimeout(() => {
      //     this.pageData.current_page++
      //     this.getList()
      //   }, 500)
      //
      // } else {
      //   this.loading = false
      // }
    },
    getTableColumns(dataList) {
      let rows = dataList.filter(value => value.row === 0)
      let d = []
      rows.forEach((item) => {
        d.push(
          {
            title: item.value || '未知',
            value: `col_${item.col}`,
            width: 140,
            header_align: 'center',
            align: 'center'
          })
      })
      return d
    },
    getTableList(dataList) {
      let rows = dataList.filter(value => value.row !== 0)
      let list = {}
      rows.forEach((item) => {
        if (!list[`row_${item.row}`]) {
          list[`row_${item.row}`] = {}
        }
        list[`row_${item.row}`][`col_${item.col}`] = item.value
      })

      return Object.values(list)
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index < 4) {
          return
        }
        if (index === 4) {
          sums[index] = '合计：'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)

        } else {
          sums[index] = ''
        }

      })
      return sums
    },
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      this.gridColumns = []
      this.showReportData(row)
      // console.log(row)
      this.$notify.info(`报表同步时间：${row.create_time}`)
    },
    showReportData(row) {
      let gridHeader = []
      if (row.grid_header) {
        gridHeader = row.grid_header ? JSON.parse(row.grid_header) : []
        if (gridHeader.columns) {
          this.gridColumns = this.calcGridColumns(gridHeader.columns)
        }
        this.gridData = this.calcGridData(row)
      } else {
        // 自由报表-没有表头
        let rows = row.datas ? JSON.parse(row.datas) : []
        this.gridColumns = this.getTableColumns(rows)
        this.gridData = this.getTableList(rows)
      }

      this.initChart()
    },

    calcGridColumns(gridHeader) {
      let cols = []
      gridHeader.forEach((item) => {
        cols.push({
          title: item.title || '未知',
          value: `${item.id}`,
          width: 120,
          header_align: 'center',
          align: 'center'
        })
      })
      return cols
    },
    calcGridData(row) {
      return row.datas ? JSON.parse(row.datas) : []
    },
    calcChartData() {
      let seriesData = [
        { value: 0, name: '简历初筛' },
        { value: 0, name: '初试' },
        { value: 0, name: '复试' },
        { value: 0, name: '拟录' },
        { value: 0, name: 'Offer环节' },
        { value: 0, name: '正式录用' }
      ]
      // 获取列对应的数据字段
      seriesData.forEach((item) => {
        let name = item.name
        // eslint-disable-next-line no-unused-vars
        let colObj = this.gridColumns.find(value => value.title === name)
        item.field = colObj ? colObj.value : null
      })
      this.gridData.forEach((row) => {
        seriesData.forEach((item) => {
          let field = item.field
          if (field) {
            let val = Number(row[field])
            if (!isNaN(val)) {
              item.value = item.value + val
            }
          }
        })
      })
      return { legend: this.legend, seriesData: seriesData }
    },
    initChart() {
      // 数据来源：gridColumns gridData
      let { legend, seriesData } = this.calcChartData()

      this.chartOption = {
        color: [
          '#409EFF',
          '#3ac2bd',
          '#ad31ff',
          '#ff9a64',
          '#67C23A',
          '#2843cc'],
        title: {
          text: '招聘数据漏斗图',
          textStyle: {
            color: '#ff3176',
            fontWeight: 'bold',
            fontSize: 20
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}'
        },
        toolbox: {
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {}
          }
        },
        legend: {
          data: legend
        },
        series: [
          {
            name: '缇苏招聘',
            type: 'funnel',
            left: '10%',
            top: 60,
            bottom: 60,
            width: '80%',
            min: 0,
            max: 100,
            minSize: '1%',
            maxSize: '100%',
            sort: 'none',
            gap: 2,
            label: {
              show: true,
              position: 'inside',
              formatter: '{b} : {c}',
              color: '#f2f2f2',
              fontStyle: 'italic',
              fontSize: 14
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: 'solid'
              }
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            emphasis: {
              label: {
                fontSize: 20
              }
            },
            //['简历初筛', '初试', '复试', '拟录', 'Offer环节', '正式录用']
            data: seriesData
          }
        ]
      }
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },

    //复制到剪切板
    copyData(row) {

      delete row.id

      const textarea = document.createElement('textarea')
      textarea.setAttribute('readonly', 'readonly')

      textarea.value = JSON.stringify(row)
      document.body.appendChild(textarea)
      textarea.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        // alert('复制成功')
        this.$notify.success('已复制到剪切板')
      }
      document.body.removeChild(textarea)
    },
    handleTransfer() {
      if (this.gridOrgData && this.gridOrgData.indexOf('{') > -1)
        this.gridForm = JSON.parse(this.gridOrgData)

    },
    async handleSave() {
      if (!this.gridForm.create_time) {
        this.$notify.warning('同步时间必填')
      }
      if (!this.gridForm.report_id) {
        this.$notify.warning('报表ID必填')
      }
      this.loadingCommit = true
      let id = await this.$api.saveBeiSenReportData(this.gridForm)
      if (id) {
        this.dialogVisible = false
        this.$notify.success('保存成功')
      }
      this.loadingCommit = false
    }
  }


}
</script>

<style scoped>
.box {
  display: flex;
}

.panel {
  padding: 5px 5px;
}

.panel > .title {
  font-weight: bold;
}
</style>
