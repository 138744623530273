<template>
  <div>
    <!--      当前报表分析-->
    <el-form :model='searchCondition' inline size='mini'>
      <el-form-item>
        <el-input v-model='searchCondition.position_title' placeholder='职位名称' clearable
                  @change='handleQuery'></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model='searchCondition.position_no' placeholder='职位编号' clearable @change='handleQuery'></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model='searchCondition.dept_name' placeholder='需求部门' clearable @change='handleQuery'></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model='searchCondition.manager' placeholder='招聘负责人' clearable @change='handleQuery'></el-input>
      </el-form-item>
      <el-form-item label=''>
        <el-date-picker
          v-model='searchCondition.date_range'
          placeholder='时间区间'
          type='daterange'
          align='right'
          unlink-panels
          range-separator='至'
          start-placeholder='开始日期'
          end-placeholder='结束日期'
          value-format='yyyy-MM-dd'
          :picker-options='pickerOptions' @change='handleQuery'>
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type='primary' icon='el-icon-search' @click='handleQuery' :disabled='loading' :loading='loading'>搜索</el-button>
      </el-form-item>
    </el-form>
    <div>
      <!--      <span>报表基本信息： {{ report.report_title || '' }}({{ report.report_id || '' }})</span>-->
      <div>
        <div style='width: 100%;height: auto'>
          <!--              漏斗图-->
          <echarts-chart :width='`100%`' :height='`800px`' :chart-option='chartOption' />
        </div>
        <div>
          <MlPageTable :all-columns='defaultColumns'
                       :table-name='tableName'
                       :data-list='dataList'
                       :limit.sync='pageData.page_size'
                       :page.sync='pageData.current_page'
                       :total.sync='total'
                       :height='height'
                       show-summary
                       :summary-method='getSummaries'
                       @getList='getList'>
          </MlPageTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from '@/pages/beisen/ReportTable'
import EchartsChart from '@/components/EchartsChart'
import MlPageTable from '@/components/common/MlPageTable'

export default {
  name: 'ReportSummary',
  components: { MlPageTable, EchartsChart, ReportTable },
  data() {
    return {
      loading: false,
      report: { report_title: '', report_id: '' },
      currReportId: null,
      searchCondition: {
        'id': null,
        'position_title': null,
        'position_no': null,
        'dept_name': null,
        'manager': null,
        date_range: []
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      total: 0,
      height: 800,
      tableName: 'beisen_recruit_report',
      dataList: [],
      pageData: { current_page: 1, page_size: 50 },
      defaultColumns: [
        { hide: true, prop: 'id', label: 'ID', width: 80, sortable: false, fixed: false },
        { hide: false, prop: 'position_title', label: '职位名称', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'position_no', label: '职位编号', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'dept_name', label: '需求部门', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'manager', label: '招聘负责人', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'recruit_num', label: '招聘人数', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'init_num', label: '简历初筛', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'first_num', label: '初试', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'second_num', label: '复试', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'proposed_num', label: '拟录', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'offer_num', label: 'Offer环节', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'hired_num', label: '正式录用', width: 120, sortable: true, fixed: false },
        { hide: true, prop: 'create_time', label: '同步时间', width: 180, sortable: true, fixed: false },
        { hide: true, prop: 'created_at', label: '创建时间', width: 140, sortable: false, fixed: false },
        { hide: true, prop: 'updated_at', label: '修改时间', width: 140, sortable: false, fixed: false }
      ],
      gridColumns: [],
      loadingGrid: false,
      gridData: [],
      gridDataTotal: 0,
      gridPageData: { current_page: 1, page_size: 20 },
      chartOption: {},
      legend: ['简历初筛', '初试', '复试', '拟录', 'Offer环节', '正式录用']
    }
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index < 4) {
          return
        }
        if (index === 4) {
          sums[index] = '合计：'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)

        } else {
          sums[index] = ''
        }

      })
      return sums
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field] && this.searchCondition[field].length > 0) {
          condition[field] = this.searchCondition[field]
        }
      })
      // if (this.searchCondition.date_range && this.searchCondition.date_range.length === 2) {
      //   condition.date_range = this.searchCondition.date_range
      // }

      // condition['report_id'] = this.searchCondition.report_id || ''
      // if (!condition['report_id']) {
      //   this.$notify.warning('报表ID未配置！！！')
      // }
      return condition
    },
    handleQuery() {
      // this.getList()
      this.getSummaryData()
      this.getChatData()
    },
    async getChatData() {
      let params = this.handleSearchCondition()
      let { chart_data, legend } = await this.$api.getBeiSenRecruitReportChatData(params)

      this.initChart(legend, chart_data)
    },
    async getReport() {
      let { report } = await this.$api.getBeiSenReport()
      this.report = report
      this.searchCondition.report_id = report.report_id || ''
    },
    async getSummaryData() {
      this.loading = true
      let params = this.handleSearchCondition()
      Object.assign(params, this.pageData)
      let { list } = await this.$api.getBeiSenRecruitReportSummaryData(params)
      this.dataList = list

      this.loading = false
    },
    async getList() {
      this.loading = true
      let params = this.handleSearchCondition()
      Object.assign(params, this.pageData)
      let { list, pages } = await this.$api.getBeiSenRecruitReportList(params)
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    },
    initChart(legend, seriesData) {
      // 数据来源：gridColumns gridData
      // let { legend, seriesData } = this.calcChartData()

      this.chartOption = {
        color: [
          '#409EFF',
          '#3ac2bd',
          '#ad31ff',
          '#ff9a64',
          '#67C23A',
          '#2843cc'],
        title: {
          text: '招聘数据漏斗图',
          textStyle: {
            color: '#ff3176',
            fontWeight: 'bold',
            fontSize: 20
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}'
        },
        toolbox: {
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {}
          }
        },
        legend: {
          data: legend
        },
        series: [
          {
            name: '缇苏招聘',
            type: 'funnel',
            left: '10%',
            top: 60,
            bottom: 60,
            width: '80%',
            min: 0,
            max: 100,
            minSize: '1%',
            maxSize: '100%',
            sort: 'none',
            gap: 2,
            label: {
              show: true,
              position: 'inside',
              formatter: '{b} : {c}',
              color: '#f2f2f2',
              fontStyle: 'italic',
              fontSize: 14
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: 'solid'
              }
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            emphasis: {
              label: {
                fontSize: 20
              }
            },
            //['简历初筛', '初试', '复试', '拟录', 'Offer环节', '正式录用']
            data: seriesData
          }
        ]
      }
    }
  },
  async created() {
    //默认时间
    let date = new Date()
    let currentDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    let monthFirstDay = `${date.getFullYear()}-${date.getMonth() + 1}-01`
    this.searchCondition.date_range = [monthFirstDay, currentDay]
    // 获取报表ID
    // await this.getReport()
    //再获取
    this.handleQuery()
  }
}
</script>

<style scoped>

</style>