<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
        <div>
          <h4>缇苏招聘数据明细</h4>
          <div>
            <ReportSummary v-if='userPermissions.indexOf("report_beisen_hr")>-1' />
          </div>
        </div>
        <!--        北森报表记录  -->
        <RecordList v-if='userPermissions.indexOf("report_beisen_report")>-1'  ref='refBsRecordList'></RecordList>
        <!--        备注-->
        <div>
          <Tips :data='tipsList'></Tips>
        </div>
      </div>
    </page-header-layout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import RecordList from '@/pages/beisen/RecordList'
import { mapGetters } from 'vuex'
import ReportTable from '@/pages/beisen/ReportTable'
import ReportSummary from '@/pages/beisen/ReportSummary'

export default {
  name: 'IndexList',
  components: { ReportSummary, ReportTable, RecordList, PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      loading: false,
      dataList: [{}],
      pageData: { current_page: 1, page_size: 100 },
      total: 0,
      searchCondition: {
        id: null
      },
      option: [],
      info: {},
      tableName: 'beisen',
      customColumnShow: false,
      tableColumns: [],
      tableList: [],
      tipsList: [
        '报表若更新失败，可能是服务器IP为加入北森白名单，导致无法调用数据接口，请及时联系管理员',
        '报表若更新被拒绝，可能是调用接口太过频繁，被限制调用（接口限制：1次/秒/企业 30次/分钟/企业）',
        '前置条件：[连接器接口] 添加：获取租户下报表（租户级+应用级）',
        '前置条件：[连接器接口] 添加：获取【分析报表、定制开发】报表表头数据',
        '前置条件：[连接器接口] 添加：获取【分析报表、定制报表】报表的列表详细数据',
        '前置条件：[连接器接口] 添加：获取异步报表生成状态',
        '前置条件：[连接器接口] 添加：获取【自由报表】数据',
        '前置条件：[连接器接口] 添加：刷新【自由报表、定制报表】数据',
      ]
    }
  },
  methods: {
    getTableColumns() {
      let rows = this.dataList.filter(value => value.row === 0)
      let d = []
      rows.forEach((item) => {
        d.push(
          {
            title: item.value || '未知',
            value: `col_${item.col}`,
            width: 140,
            header_align: 'center',
            align: 'center'
          })
      })
      return d
    },
    getTableList() {
      let rows = this.dataList.filter(value => value.row !== 0)
      let list = {}
      rows.forEach((item) => {
        if (!list[`row_${item.row}`]) {
          list[`row_${item.row}`] = {}
        }
        list[`row_${item.row}`][`col_${item.col}`] = item.value
      })

      return Object.values(list)
    },
    handleQuery() {
      this.dataList = []
      this.pageData.current_page = 1
      if (this.searchCondition.id) {
        this.getInfo()
      }

    },
    async getInfo() {
      let { info } = await this.$api.getBeiSenReportInfo(this.searchCondition.id)
      this.info = info
      this.dataList = info.datas ? JSON.parse(info.datas) : []
      this.tableColumns = this.getTableColumns()
      this.tableList = this.getTableList()

    },
    // async getList() {
    //   this.loading = true
    //   let params = { id: this.searchCondition.id }
    //   Object.assign(params, this.pageData)
    //   let { list, pages } = await this.$api.getBeiSenReportList(params)
    //   this.dataList = [...this.dataList, ...list]
    //   this.pageData.current_page = pages.current_page || 1
    //   this.pageData.page_size = pages.page_size || 100
    //   this.total = pages.total
    //   if (pages.total > this.dataList.length) {
    //     //加载下一页
    //     setTimeout(() => {
    //       this.pageData.current_page++
    //       this.getList()
    //     }, 500)
    //
    //   } else {
    //     this.loading = false
    //   }
    // },
    async getOptions() {
      let { option } = await this.$api.getBeiSenReportOption()
      this.option = option
    }
  },
  mounted() {
    // this.getOptions()
  }
}
</script>

<style scoped>

</style>