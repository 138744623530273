<template>
  <div>
    <div class='box'>
      <el-link type='primary' class='title' icon='el-icon-refresh' @click='getList'>缇苏报表（北森系统）</el-link>
      <el-form v-model='searchCondition' inline>
        <el-form-item>
          <el-input v-model='searchCondition.report_id' placeholder='报表ID' clearable @change='handleQuery'></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model='searchCondition.name' placeholder='报表名称' clearable @change='handleQuery'></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model='searchCondition.reportType' placeholder='报表类型' clearable>
            <el-option v-for='(option,index) in typeOption' :key='index' :value='option.value'
                       :label='option.label'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' icon='el-icon-search' @click='handleQuery' :disabled='loading' :loading='loading'>
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <div>
        <MlPageTable :all-columns='defaultColumns'
                     :table-name='tableName'
                     :data-list='dataList'
                     :limit.sync='pageData.page_size'
                     :page.sync='pageData.current_page'
                     :total.sync='total'
                     :height='height'
                     @getList='getList'>
          <el-table-column label='操作' width='140' align='center'>
            <template slot-scope='{row}'>
              <el-button type='text' icon='el-icon-s-grid' @click='showDataDialog(row)' v-if='row.reportType!=3'
                         title='查看最新数据'>查看
              </el-button>
              <el-button type='text' icon='el-icon-s-data' @click='showReportTable(row)'
                         v-if='userPermissions.indexOf("report_beisen_list")>-1'>分析
              </el-button>
            </template>
          </el-table-column>
        </MlPageTable>
      </div>
      <div>
        <el-divider>报表分析</el-divider>
        <!--      当前报表分析-->
        <ReportTable :report-id='currReportId' v-if='currReportId'></ReportTable>
      </div>

    </div>

    <el-dialog
      title='报表数据'
      :visible.sync='showGirdDialog'
      fullscreen
      width='90%'
    >
      <div>
        <el-link class='title' @click='getGridData'>报表:{{ currReport.name || '' }}（{{ currReport.report_id || '' }}）
        </el-link>
        <el-link type='primary' style='float: right' icon='el-icon-refresh' @click='getGridHeader("Y")'>刷新表头</el-link>
        <div class='default-table'>
          <div
            v-loading='loadingGrid'
            element-loading-text='玩命加载中……'
            element-loading-spinner='el-icon-loading'>
            <el-table
              :data='gridData' border>
              <el-table-column
                width='50'
                label='序号' header-align='center' align='center' fixed>
                <template slot-scope='scope'>
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-for='(v,k) in gridColumns'
                :key='k'
                :prop="v.field?v.field:typeof(v.value)=='string'?v.value:''"
                :label='v.title'
                :width='v.width'
                :show-overflow-tooltip='true'
                :header-align="v.header_align?v.header_align:'center'"
                :align="v.align?v.align:'center'"
                :sortable='v.sortable?v.sortable:false'>
                <template slot-scope='scope'>
                  <span v-html='scope.row[v.value]'></span>
                </template>
              </el-table-column>
            </el-table>
            <m-pagination ref='gridTablePagination'
                          :limit.sync='gridPageData.page_size'
                          :page.sync='gridPageData.current_page'
                          :total.sync='gridDataTotal'
                          @pagination='getGridData'></m-pagination>
          </div>
        </div>

      </div>

      <!--      <span slot='footer' class='dialog-footer'>-->
      <!--        <el-button @click='showGirdDialog = false'>取 消</el-button>-->
      <!--        <el-button type='primary' @click='showGirdDialog = false'>确 定</el-button>-->
      <!--      </span>-->
    </el-dialog>
  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import ReportTable from '@/pages/beisen/ReportTable'
import { mapGetters } from 'vuex'

export default {
  name: 'RecordList',
  components: { MlPageTable, ReportTable },
  computed: {
    ...mapGetters(['userPermissions']),
    gridColumns() {
      let cols = []
      this.gridHeader.forEach((item) => {
        cols.push({
          title: item.title || '未知',
          value: `${item.id}`,
          width: 120,
          header_align: 'center',
          align: 'center'
        })
      })
      return cols
    }
  },
  data() {
    return {
      report: { report_title: '', report_id: '' },//已对接的报表
      loading: false,
      dataList: [],
      pageData: { current_page: 1, page_size: 50 },
      total: 0,
      searchCondition: {
        id: null,
        name: '招聘',
        report_id: null,
        reportType: null
      },
      tableName: 'beisen_report_record',
      defaultColumns: [
        { hide: true, prop: 'id', label: 'ID', width: 80, sortable: false, fixed: false },
        { hide: false, prop: 'report_id', label: '报表ID', width: 180, sortable: false, fixed: false },
        { hide: false, prop: 'name', label: '报表名称', width: 180, sortable: true, fixed: false },
        { hide: false, prop: 'description', label: '报表描述', width: 280, sortable: false, fixed: false },
        {
          hide: false, prop: 'reportType', field: '_reportType', label: '报表类型', width: 80, sortable: false,
          fixed: false
        },
        {
          hide: false, prop: 'reports_count', label: '报表记录', width: 80, sortable: false,
          fixed: false
        },
        { hide: false, prop: 'created_at', label: '创建时间', width: 140, sortable: false, fixed: false },
        { hide: false, prop: 'updated_at', label: '修改时间', width: 140, sortable: false, fixed: false }
      ],
      height: 500,
      typeOption: [
        { label: '分析报表', value: 1 },
        { label: '定制开发报表', value: 2 },
        { label: '自由报表', value: 3 }
      ],
      showGirdDialog: false,
      currReport: {},
      gridHeader: [],
      gridData: [],
      gridDataTotal: 0,
      gridPageData: { current_page: 1, page_size: 20 },
      loadingGrid: false,
      currReportId: ''
    }
  },
  methods: {
    async getReport() {
      let { report } = await this.$api.getBeiSenReport()
      this.report = report
      // this.searchCondition.report_id = report.report_id || ''
    },
    handleSearchCondition() {
      let cond = {}
      Object.keys(this.searchCondition).forEach((k) => {
        if (this.searchCondition[k])
          cond[k] = this.searchCondition[k]
      })
      return cond
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    async getList() {
      this.loading = true
      let params = this.handleSearchCondition()
      Object.assign(params, this.pageData)
      let { list, pages } = await this.$api.getBeiSenReportRecordList(params)
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 10
      this.total = pages.total || 0
      this.loading = false
    },
    async showDataDialog(row) {
      this.currReport = { ...row }
      this.showGirdDialog = true
      this.loadingGrid = false
      this.gridData = []
      this.gridDataTotal = 0
      await this.getGridHeader()
      await this.getGridData()
    },
    async getGridHeader(force) {
      let { columns, isComplex } = await this.$api.getBeiSenReportGridHeader(this.currReport.id, force)
      this.gridHeader = columns || []

      this.$notify.info(`${isComplex ? '复合表格(未对接)' : '简单表格'}`)
    },
    async getGridData() {
      this.loadingGrid = true
      //getBeiSenReportGridData
      let params = { id: this.currReport.id }
      Object.assign(params, this.gridPageData)
      let {
        list,
        pages
      } = await this.$api.getBeiSenReportGridData(params)
      this.loadingGrid = false
      this.gridData = list
      // console.log(list)
      // this.gridPageData.current_page = pages.current_page || 1
      // this.gridPageData.page_size = pages.page_size || 20
      if (pages.total > 0)
        this.gridDataTotal = pages.total
    },
    showReportTable(row) {
      this.currReportId = row.report_id
      // this.$notify.info(this.currReportId)
    }
  },
  async created() {
    await this.getReport()
    await this.getList()
  }
}
</script>

<style scoped>
.box {
  margin-top: 10px;
  padding: 10px 0;
  /*border-top: #e97ca1 1px dashed;*/
}

.title {
  font-size: 20px;
  font-weight: bold;
}
</style>
